/* theme.css - Custom styles for the React Material Pokémon Site */

/* Importing Bootstrap Material Design base styles */
@import 'bootstrap-material-design/dist/css/bootstrap-material-design.min.css';
@import './other/blogger2013.css';

/* Define colors for Pokémon types */
:root {
  --type-bug: #A8B820;
  --type-night: #705848;
  --type-dragon: #7038F8;
  --type-electric: #F8D030;
  --type-fairy: #EE99AC;
  --type-fighting: #C03028;
  --type-fire: #F08030;
  --type-flying: #A890F0;
  --type-ghost: #705898;
  --type-plant: #78C850;
  --type-ground: #E0C068;
  --type-ice: #98D8D8;
  --type-neutral: #A8A878;
  --type-poison: #A040A0;
  --type-psychic: #F85888;
  --type-rock: #B8A038;
  --type-steel: #B8B8D0;
  --type-water: #6890F0;
  --type-paper: #FFFFFF;
  --type-plastic: #B5651D;
  --type-salt: #FFFFFF;
  --type-rubber: #808080;
  --type-sugar: #FFFFFF;
}

.header {
  user-select: none;
}

/* Header styles */
.header .navbar {
  background-color: #00a4e9!important; /* Blue color for the header */
}

/* FRAMEWORK.JS LATER MERGE WITH ----> */
logo .badge {
  background: #ff9800 !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  color: #000000de !important;
  font-weight: unset!important;
  border: 1px solid #000000de;
  display: unset;
  margin-left: 12px;
  font-weight: 500;
  line-height: inherit;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  padding: 4px 4px 4px 4px !important;
}

.header .badge {
  padding: 5px 10px;
  font-size: 0.8em;
}

/*page-common.css:*/
body {
  font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  line-height: inherit !important;
}
logo, .logo, .breaditem {
  position: relative;
  min-width: 47px;
  font-size: 30px;
  color: #fff !important;
  border-radius: 10px;
  padding: 0px 0px 8px 3px;
}
.footer, footer {
  background: #00A185;
  width: 100%;
  color: #efefef;
  padding: 10px;
  box-sizing: border-box;
}
.footer-shadow {
  width: calc(100% + 40px);
  height: 24px;
  border-bottom: 1px solid #333;
  box-shadow: 0px 16px 16px -8px black;
  z-index: 1000;
  position: relative;
  top: -34px;
  left: -20px;
}
footer a {
  color: #FFFFFF;
  opacity: 0.5;
}
footer a:hover {
  opacity: 1;
}
.social-icons {
  float: right;
}

/*page-custom.css:*/
.logo, logo {
  width: 140px !important;
  height: 38px !important;
  background: url(../../public/img/institutional/2018/logotype.png) no-repeat no-repeat 0px 0px !important;
    background-size: auto;
  background-size: auto !important;
  background-size: 140px !important;
  padding: 0 0px 45px 120px !important;
  line-height: 41px !important;
  top: 3px !important;
  color: #333 !important;
  display: inline-block;
  border-radius: 0px !important;
}
.footer, footer {
  background: #00a4e9;
  color: #fff;
}

/* React Bootstrap Material exclusive:*/
.logo, logo {
  line-height: 20px !important;
  top: -2px !important;
  left: -15px;
}
/* <---- FRAMEWORK.JS LATER MERGE WITH */

/* Search component styles */
.search {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.search input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search button {
  padding: 10px;
  background-color: #4CAF50; /* Green color for the search button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.year-group {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.year-group a {
  margin-right: 5px;
}

.chartyears {
  user-select: none;
  background: #121435;
}
.chartyears p {
  color: #ff5722;
  font-family: Arvo Bold;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 0;
  padding-bottom: 25px;
  position: relative;
  top: 33px;
  left: 15px;
}

.footer {
  user-select: none;
  background-color: #00a4e9!important; /* Blue color for the header */
}

/* Pokémon list styles */
.pokemon-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pokemon-list > a, .pokemon-list > a:hover {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

.pokemon-item {
  user-select: none;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  text-align: center;
}

.pokemon-item img {
  user-select: none;
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.pokemon-item .type {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.type-badge{border-radius:5px;padding:3px;border:1px solid;white-space:nowrap;margin-right:5px;text-decoration:none;color:initial}
.type-badge:hover{color:initial;text-decoration:none}

/* Dynamically assign background colors based on Pokémon type */
.type-bug { background-color: var(--type-bug) }
.type-night { background-color: var(--type-night) }
.type-dragon { background-color: var(--type-dragon) }
.type-electric { background-color: var(--type-electric) }
.type-fairy { background-color: var(--type-fairy) }
.type-fighting { background-color: var(--type-fighting) }
.type-fire { background-color: var(--type-fire) }
.type-flying { background-color: var(--type-flying) }
.type-ghost { background-color: var(--type-ghost) }
.type-plant { background-color: var(--type-plant) }
.type-ground { background-color: var(--type-ground) }
.type-ice { background-color: var(--type-ice) }
.type-neutral { background-color: var(--type-neutral) }
.type-poison { background-color: var(--type-poison) }
.type-psychic { background-color: var(--type-psychic) }
.type-rock { background-color: var(--type-rock) }
.type-steel { background-color: var(--type-steel) }
.type-water { background-color: var(--type-water) }
.type-paper { background-color: var(--type-paper) }
.type-plastic { background-color: var(--type-plastic) }
.type-salt { background-color: var(--type-salt) }
.type-rubber { background-color: var(--type-rubber) }
.type-sugar { background-color: var(--type-sugar) }

.type-matchups {
  display: flex;
  justify-content: space-between;
}
.type-matchups h3 {
  margin-bottom: 10px;
}
.type-matchups a {
  margin-right: 10px;
}

.evolution-item {
  user-select: none;
}

/* Type page styles */
.type-page {
  padding: 20px;
}

.prev-button img, .next-button img {
  height: 100px;
}

.prev-button, .next-button {
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color:#fff;
}
.prev-button:hover, .next-button:hover {
  background-color:#00a4e9;
}

.prev-button {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-right: 1px dotted;
}

.next-button {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.tamicons {
  display: inline-block
}
.tamicons .cartoon, .tamicons .pixel {
  display: inline-block;
}
.tamicons .cartoon img {
  width: 128px;
  height: 128px;
}

.gender-switch button {
  border-radius: 50%;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}
.gender-switch button.default {
  /*background: url(../../public/img/ui/icon-red.png);
  background-repeat: no-repeat;*/
  background-color: #fff;
  /*background-position: 7px 7px;*/
}
.gender-switch button.female {
  /*background: url(../../public/img/ui/icon-blue.png);
  background-repeat: no-repeat;*/
  background-color: #fff;
  /*background-position: 7px 7px;*/
}
.gender-switch button {
  filter: grayscale(100%);
  box-shadow: 0px 0px 2px 2px transparent;
  border: 2px solid transparent;
}
.gender-switch button.active {
  filter: none;
  box-shadow: 0px 0px 2px 2px #fff;
}
.gender-switch button.default.active {
  border: 2px solid #00a4e9;
}
.gender-switch button.female.active {
  border: 2px solid #ec2a78;
}

.pedia-switch button {
  border-radius: 50%;
  border: none;
  padding: 15px;
  margin: 5px;
  cursor: pointer;
}
.pedia-switch button.red {
  background: url(../../public/img/ui/icon-red.png);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 7px 7px;
}
.pedia-switch button.blue {
  background: url(../../public/img/ui/icon-blue.png);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 7px 7px;
}
.pedia-switch button {
  filter: grayscale(100%);
  box-shadow: 0px 0px 2px 2px transparent;
  border: 2px solid transparent;
}
.pedia-switch button.active {
  filter: none;
  box-shadow: 0px 0px 2px 2px #fff;
}
.pedia-switch button.red.active {
  border: 2px solid #ec2a78;
}
.pedia-switch button.blue.active {
  border: 2px solid #00a4e9;
}

.type-header {
  border-radius: 5px;
  padding: 0 5px 0 5px;
}

/* Description styles */
.pokemon-description p {margin: 10px 0;}
.pokemon-details a {margin-right: 5px;}

.evolutions-graph img {
  height: 100px;
}

.game-details .cover {
  max-width: 400px
}

.game-item {
  user-select: none;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  text-align: center;
}

.game-item img {
  user-select: none;
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.game-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.game-list > a, .game-list > a:hover {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

.region-item {
  user-select: none;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  text-align: center;
}

.region-item img {
  user-select: none;
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.region-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.region-list > a, .region-list > a:hover {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

.category-item {
  user-select: none;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  text-align: center;
}

.category-item img {
  user-select: none;
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.categories-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.categories-list > a, .categories-list > a:hover {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

/* Pokémon list styles */
.mintables-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mintables-list > a, .mintables-list > a:hover {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

.mintables-item {
  user-select: none;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  text-align: center;
}

.mintables-item img {
  user-select: none;
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mintables .type {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

/* Description styles */
.mintable-description p {margin: 10px 0;}
.mintable-details a {margin-right: 5px;}

.mintable-details .cover {
  max-width: 400px
}
